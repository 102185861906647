import React from "react";
import PropTypes from "prop-types";

export const Card = ({
  children,
  backgroundColor,
  shadow,
  padding,
  extraClass,
}) => {
  return (
    <div
      className={`h-auto w-auto rounded-lg  ${padding}  ${backgroundColor} ${shadow} ${extraClass}`}
    >
      {children}
    </div>
  );
};

Card.propTypes = {
  backgroundColor: PropTypes.string,
  shadow: PropTypes.string,
  extraClass: PropTypes.string,
};

Card.defaultProps = {
  backgroundColor: "bg-white",
  shadow: "shadow-md",
  padding: "p-10",
};
