import React, { useRef } from "react";
import { Sidebar } from "../../partials/Sidebar";
import { Header } from "../../partials/Header";
import { useEffect } from "react";

//Socket

import socket from "../../utils/socket";

export const AdminTemplate = ({ children }) => {
  const ref = useRef();

  useEffect(() => {
    let SIO_ID = localStorage.getItem("SIO_ID");

    SIO_ID = JSON.parse(SIO_ID);
    socket.auth = SIO_ID;
    socket.connect();
    
  }, []);

  return (
    <>
      <Sidebar />
      <Header />
      <div
        id="mainContainer"
        ref={ref}
        className=" bg-white top-0 right-0 left-0 bottom-0 overflow-y-scroll absolute"
        style={{ marginLeft: "78px", marginTop: "60px", zIndex: 100 }}
      >
        <div className="relative h-80 bg-green-200">
          <div className="py-8 px-4">
            {React.cloneElement(children, { container: ref })}
            <div className="w-full mt-3">
              <p className="text-green-500 text-sm">
                Coded with ♥️ by{" "}
                <a
                  href="https://carlospadron.dev"
                  target="_blank"
                  rel="noreferrer"
                  className="text-purple-500"
                >
                  Carlos Padrón
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
