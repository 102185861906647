import React from "react";
import { Constants } from "../../utils/constants";

const LazyLoad = () => {
  return (
    <div className="w-screen h-screen bg-green-200 flex justify-center items-center">
      <img
        src={Constants.defaultImg}
        alt=""
        className="rounded-md w-64 h-64 animate__animated animate__infinite infinite animate__bounce"
      />
    </div>
  );
};

export default LazyLoad;
