import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isFetching: false,
  isSuccess: false,
  isError: false,
  errorMessage: "",
  data: {},
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    reset: (state, action) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = false;
      state.data = {};
    },
    fetchingInfo: (state, action) => {
      state.isFetching = true;
      state.isSuccess = false;
      state.isError = false;
    },
    fetchingSuccess: (state, action) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
      state.data = action.payload.data;
    },
    fetchingFailure: (state, action) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = true;
      state.errorMessage = action.payload.error;
    },
  },
});

export const { reset, fetchingInfo, fetchingSuccess, fetchingFailure } =
  dashboardSlice.actions;
export default dashboardSlice.reducer;
