import React from "react";
import { useSelector } from "react-redux";

import { Link, useParams } from "react-router-dom";

const HeaderClient = () => {
  const state = useSelector(
    (state) => state.clientMenu,
    (prev, next) => {
      return prev.preorder.length !== next.preorder.length ? false : true;
    }
  );

  const { idRestaurant } = useParams();

  return (
    <>
      <div
        className="headerClient w-screen bg-white"
        style={{ height: "60px" }}
      >
        <div
          className="flex justify-end items-center"
          style={{ height: "60px" }}
        >
          <Link to={`/${idRestaurant}/preporden`}>
            <div className="relative inline-block mr-5 mt-2">
              <i className="bx bx-cart-alt text-purple-300 text-3xl cursor-pointer"></i>
              <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-purple-600 rounded-full">
                {state.preorder.length}
              </span>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default HeaderClient;
