const generalService = {
  getToken: () => {
    let session = localStorage.getItem("session");

    if (session === undefined) {
      return "";
    } else {
      let sessionObj = JSON.parse(session);
      return sessionObj.token;
    }
  },

  validateImage: (image) => {
    let validTypes = ["image/jpeg", "image/png"];

    if (validTypes.indexOf(image.type) === -1) {
      return {
        error: true,
        message: "Solo se aceptan imágenes PNG y JPG",
      };
    }

    if (image.size / 1024 / 1024 > 5) {
      return {
        error: true,
        message: "La imagen exede el tamaño máximo permitido. 5mb ",
      };
    }
    return {
      error: false,
      message: "",
    };
  },
};

export default generalService;
