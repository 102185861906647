export const Constants = {
  adminUser: "admin",
  dinerUser: "diner",
  kitchenUser: "kitchen",
  defaultImg: "/SnapMenuNameAndLogo.png",
};

export const environments = {
  //BASE_URL: "http://165.22.178.123:81",
  //BASE_URL: "https://doctorpadron.com.mx/snapmenu/api",
  BASE_URL: "http://localhost:3100",
};
