import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import AdminRoutes from "./AdminRoutes";

//Error
import Page404 from "../pages/errors/Page404";
import LazyLoad from "../pages/lazyload/LazyLoad";

//Pages
const LoginPage = React.lazy(() => import("../pages/LoginPage"));
//AdminPage
const DashboardPage = React.lazy(() =>
  import("../pages/admin/Dashboard/DashboardPage")
);

const MenuPage = React.lazy(() => import("../pages/admin/Menus/MenuPage"));
const AddMenuPage = React.lazy(() =>
  import("../pages/admin/Menus/AddMenuPage")
);
const EditMenuPage = React.lazy(() =>
  import("../pages/admin/Menus/EditMenuPage")
);
const OrdersPage = React.lazy(() => import("../pages/admin/Orders/OrdersPage"));
const RestaurantPage = React.lazy(() =>
  import("../pages/admin/Restaurant/RestaurantPage")
);

export const DomainRoutes = () => {
  const user = useSelector((state) => state.auth);

  return (
    <React.Suspense fallback={<LazyLoad />}>
      <Router>
        <Switch>
          <Route exact path="/" component={LoginPage} />;
          <Route exact path="/login" component={LoginPage} />;
          <AdminRoutes
            exact
            path="/dashboard"
            user={user}
            component={DashboardPage}
          />
          <AdminRoutes exact path="/menus" user={user} component={MenuPage} />
          <AdminRoutes
            exact
            path="/menus/nuevo"
            user={user}
            component={AddMenuPage}
          />
          <AdminRoutes
            exact
            path="/menus/:id"
            user={user}
            component={EditMenuPage}
          />
          <AdminRoutes
            exact
            path="/ordenes"
            user={user}
            component={OrdersPage}
          />
          <AdminRoutes
            exact
            path="/restaurante"
            user={user}
            component={RestaurantPage}
          />
          <Route component={Page404} />;
        </Switch>
      </Router>
    </React.Suspense>
  );
};
