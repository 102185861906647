import { createSlice } from "@reduxjs/toolkit";

const initialState = localStorage.getItem("session")
  ? JSON.parse(localStorage.getItem("session"))
  : {
      role: "",
      token: "",
      isFetching: false,
      isSuccess: false,
      isError: false,
      errorMessage: "",
      isAuthenticated: false,
      restaurantID: "",
    };

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logInPending: (state, action) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = "";
    },
    logInSuccess: (state, action) => {
      state.role = action.payload.role;
      state.token = action.payload.token;
      state.restaurantID = action.payload.restaurantID;
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
      state.isAuthenticated = true;
    },
    logInFailure: (state, action) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = action.payload.error;
    },
    logOut: (state, action) => {
      state.role = "";
      state.token = "";
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = "";
      state.isAuthenticated = false;
      state.restaurantID = "";
    },
  },
});

export const { logInPending, logInSuccess, logInFailure, logOut } =
  authSlice.actions;
export default authSlice.reducer;
