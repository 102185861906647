import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./assets/global.css";

import { MainRouter } from "./routes/MainRouter";

function App() {
  return (
    <>
      <ToastContainer theme="dark" />
      <MainRouter />
    </>
  );
}

export default App;
