import React from "react";
import HeaderClient from "./HeaderClient/HeaderClient";

const ClientTemplate = ({ children }) => {
  return (
    <div className="main-container bg-white top-0 right-0 left-0 bottom-0 overflow-y-scroll absolute">
      <div className="relative h-80 bg-green-200">
        <div className="content">
          <HeaderClient />
          {React.cloneElement(children)}

          {/* Footer */}
          <div className="w-screen text-center mb-4">
            <p className="text-green-500 text-sm">
              Coded with ♥️ by{" "}
              <a
                href="https://carlospadron.dev"
                target="_blank"
                rel="noreferrer"
                className="text-purple-500"
              >
                Carlos Padrón
              </a>
            </p>
          </div>
          {/* /Footer */}
        </div>
      </div>
    </div>
  );
};

export default ClientTemplate;
