import React from "react";
//Redux
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  updatePreOrder,
  updatePreorderState,
  orderingDishes,
  orderingDishesSuccess,
  orderingDishesFailure,
  resetCart,
} from "../../../redux/features/clientMenuSlice";

//Components
import { Card } from "../../../components/Card";
import { toast } from "react-toastify";

//Hooks
import { useHistory, useParams } from "react-router-dom";
import { useFormik } from "formik";

//Utils
import { Constants } from "../../../utils/constants";

//Services

import ordersService from "../../../services/ordersService";
import { useEffect } from "react";

let initialFormState = {
  clientName: "",
  phone: "",
  address: "",
  paymentMethod: "",
};

const validate = (values) => {
  const errors = {};

  if (!values.clientName) {
    errors.clientName = "El nombre es requerido.";
  }

  if (!values.phone) {
    errors.phone = "El teléfono es requerido.";
  }

  if (!values.address) {
    errors.address = "La dirección es requerida.";
  }

  if (!values.paymentMethod) {
    errors.paymentMethod = "El método de pago es requerido.";
  }

  return errors;
};

const OrderDetails = () => {
  const menuState = useSelector((state) => state.clientMenu);

  const { idRestaurant } = useParams();

  const dispatch = useDispatch();

  const history = useHistory();

  //Form
  const formik = useFormik({
    initialValues: initialFormState,
    validate,
    enableReinitialize: true,
    onSubmit: (formValue) => {
      orderDishes(formValue);
    },
  });

  //Actions
  const increase = (id) => {
    dispatch(updatePreOrder({ action: "increase", id }));
    let cart = JSON.parse(localStorage.getItem("CART"));

    let indexIncrease = cart.findIndex((dish) => dish.id === id);

    cart[indexIncrease].details.quantity =
      cart[indexIncrease].details.quantity + 1;

    localStorage.setItem("CART", JSON.stringify(cart));
  };

  const decrease = (id) => {
    let dish = menuState.preorder.find((dish) => dish.id === id);
    if (dish.details.quantity === 1) {
      dispatch(updatePreOrder({ action: "remove", id }));
      let cart = JSON.parse(localStorage.getItem("CART"));

      cart = cart.filter((dish) => dish.id !== id);

      localStorage.setItem("CART", JSON.stringify(cart));
    } else {
      dispatch(updatePreOrder({ action: "decrease", id }));

      let cart = JSON.parse(localStorage.getItem("CART"));

      let indexIncrease = cart.findIndex((dish) => dish.id === id);

      cart[indexIncrease].details.quantity =
        cart[indexIncrease].details.quantity - 1;

      localStorage.setItem("CART", JSON.stringify(cart));
    }
  };

  const remove = (id) => {
    dispatch(updatePreOrder({ action: "remove", id }));

    let cart = JSON.parse(localStorage.getItem("CART"));

    cart = cart.filter((dish) => dish.id !== id);

    localStorage.setItem("CART", JSON.stringify(cart));
  };

  const orderDishes = async (form) => {
    let order = [...menuState.preorder];

    let total = 0;
    for (const dish of order) {
      total = dish.details.quantity * dish.details.price;
    }

    order = order.map((dish) => {
      return {
        dishID: dish._id,
        name: dish.name,
        price: dish.details.price,
        quantity: dish.details.quantity,
        notes: dish.details.description,
        portion: dish.details._id,
      };
    });

    const body = { ...form, order, restaurantID: idRestaurant, total };

    dispatch(orderingDishes());

    try {
      let response = await ordersService.placeOrder(body);
      let json = await response.json();

      if (json.error) {
        dispatch(orderingDishesFailure(json.message));
        return;
      }

      dispatch(orderingDishesSuccess({ ...body, orderID: json.response }));
      localStorage.removeItem("CART");
    } catch (error) {
      console.log(error);
      dispatch(orderingDishesFailure(error.message));
    }
  };

  const reset = () => {
    dispatch(resetCart());
    history.push(`/${idRestaurant}`);
  };

  //Components
  const NoDishes = () => {
    return (
      <div className="flex flex-col items-center">
        <i
          className="bx bx-wink-smile text-yellow-300"
          style={{ fontSize: "70px" }}
        ></i>
        <h2 className="text-2xl font-bold text-green-900 mb-3 text-center">
          No has preodrenado platillos
        </h2>
        <h2 className="text-lg font-bold text-green-900 mb-3 text-center">
          Los platillos que selecciones los podrás consultar aquí antes de
          ordenarlos.
        </h2>
      </div>
    );
  };

  const DishesList = () => {
    return menuState.preorder.map((dish) => {
      return (
        <Card key={dish.id} extraClass="my-3" padding="p-5">
          <div className="flex justify-between">
            <div className="w-auto">
              <h1 className="font-medium text-lg">{dish.name}</h1>
              <h1 className="mt-3 text-sm text-red-500 font-semibold">
                ${dish.details.price}
              </h1>
            </div>
            <div className="dish-image">
              <img
                src={
                  dish.imageURL === "" ||
                  dish.imageURL === null ||
                  dish.imageURL === undefined
                    ? Constants.defaultImg
                    : dish.imageURL
                }
                className="rounded-lg w-20 h-20 object-cover"
                style={{
                  minWidth: "80px",
                }}
                alt=""
              />
            </div>
          </div>
          <div className="dish-notes mt-4">
            <p className="text-gray-400">{dish.details.notes}</p>
          </div>
          <div className="dish-quantity mt-4">
            <div className="flex justify-between">
              <div className="increase-decrease-buttons flex ">
                <button
                  className="mx-auto my-auto bg-purple-500 flex justify-center items-center rounded-md h-7 w-7 mr-3"
                  onClick={() => {
                    decrease(dish.id);
                  }}
                >
                  <i className="bx bx-minus text-lg  text-white font-md"></i>
                </button>
                <span className="flex justify-center items-center">
                  {dish.details.quantity}
                </span>
                <button
                  className="mx-auto my-auto bg-purple-500 flex justify-center items-center rounded-md h-7 w-7 ml-3"
                  onClick={() => {
                    increase(dish.id);
                  }}
                >
                  <i className="bx bx-plus  text-lg text-white font-md"></i>
                </button>
              </div>
              <div className="edit-remove buttons">
                <i
                  className="bx bx-trash text-2xl text-red-500 cursor-pointer"
                  onClick={() => {
                    remove(dish.id);
                  }}
                ></i>
              </div>
            </div>
          </div>
        </Card>
      );
    });
  };

  const PreOrderSummary = () => {
    let total = 0;
    menuState.preorder.forEach((dish) => {
      total += dish.details.price * dish.details.quantity;
    });

    return (
      <>
        <div className="w-full flex justify-between p-2 mt-2 rounded">
          <p className="text-bold text-lg">Total</p>
          <p className="text-bold text-lg">${total}</p>
        </div>
        <div className="w-full">
          <hr />
        </div>
        <button
          onClick={() => dispatch(updatePreorderState("clientInfo"))}
          className="w-full p-2 mt-3 rounded bg-green-500 text-bold text-lg text-white"
        >
          Continuar
        </button>
      </>
    );
  };

  //Hooks
  useEffect(() => {
    if (menuState.orderingDishesFailure) {
      toast.error(menuState.message, {
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        theme: "colored",
      });
    }
  }, [menuState.orderingDishesFailure]);

  return (
    <>
      <div className="container absolute w-screen">
        <div
          className={`animate__animated  animate__fast ${
            menuState.preorderState === "details"
              ? "block animate__fadeIn"
              : "hidden animate__fadeOut "
          } 
          
          
          w-screen
          
          flex flex-col items-center  h-full`}
        >
          <div className="w-full  mt-10 mb-auto">
            <Card extraClass="mx-3 mb-4">
              <div className="flex justify-between items-center">
                <button
                  className="flex items-center h-8 px-4 py-2 rounded-md text-md font-medium border shadow-sm focus:outline-none focus:ring transition text-yellow-600 bg-yellow-50 border-yellow-200 hover:bg-yellow-100 active:bg-yellow-200 focus:ring-white mr-0 m-0 sm:mr-5"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <i className="bx bx-chevron-left"></i>
                </button>
                <h1 className="text-2xl font-bold text-green-900 mb-3 text-center">
                  Platillos preordenados
                </h1>
              </div>
              <hr />
              <div className="w-full">
                {menuState.preorder.length === 0 ? (
                  <NoDishes />
                ) : (
                  <>
                    <DishesList />
                    <PreOrderSummary />
                  </>
                )}
              </div>
            </Card>
          </div>
        </div>

        <div
          className={`animate__animated  animate__fast ${
            menuState.preorderState === "clientInfo"
              ? "block animate__fadeIn"
              : "hidden animate__fadeOut "
          } w-full flex flex-col items-center  h-full`}
        >
          <div className="w-full  mt-10 mb-auto">
            <Card extraClass="mx-3 mb-4">
              <div className="flex justify-between items-center">
                <button
                  className="flex items-center h-8 px-4 py-2 rounded-md text-md font-medium border shadow-sm focus:outline-none focus:ring transition text-yellow-600 bg-yellow-50 border-yellow-200 hover:bg-yellow-100 active:bg-yellow-200 focus:ring-white mr-0 m-0 sm:mr-5"
                  onClick={() => dispatch(updatePreorderState("details"))}
                >
                  <i className="bx bx-chevron-left"></i>
                </button>
                <h1 className="text-2xl font-bold text-green-900 mb-3 text-center">
                  Termina la orden
                </h1>
              </div>
              <hr />
              <div className="w-full pt-3">
                <form onSubmit={formik.handleSubmit} autoComplete="off">
                  <div className="grid-rows-1 flex flex-col items-start mb-4">
                    <h3 className="text-center font-medium text-md text-gray-500">
                      Nombre
                    </h3>
                    <input
                      id="clientName"
                      name="clientName"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.clientName}
                      className={`w-full h-8 px-2 text-sm text-gray-700 placeholder-gray-600 border rounded-lg shadow-sm focus:border-transparent focus:outline-none focus:border-green-200 focus:ring-1 focus:ring-green-500  ${
                        formik.touched.clientName && formik.errors.clientName
                          ? "border-red-500"
                          : "border-gray-300"
                      }`}
                      type="text"
                    />
                    {formik.touched.clientName && formik.errors.clientName ? (
                      <small className="text-red-500">
                        {formik.errors.clientName}
                      </small>
                    ) : null}
                  </div>
                  <div className="grid-rows-1 flex flex-col items-start mb-4">
                    <h3 className="text-center font-medium text-md text-gray-500">
                      Teléfono
                    </h3>
                    <input
                      id="phone"
                      name="phone"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`w-full h-8 px-2 text-sm text-gray-700 placeholder-gray-600 border rounded-lg shadow-sm focus:border-transparent focus:outline-none focus:border-green-200 focus:ring-1 focus:ring-green-500 ${
                        formik.touched.phone && formik.errors.phone
                          ? "border-red-500"
                          : "border-gray-300"
                      }`}
                      type="text"
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <small className="text-red-500">
                        {formik.errors.phone}
                      </small>
                    ) : null}
                  </div>

                  <div className="grid-rows-1 flex flex-col items-start mb-4">
                    <h3 className="text-center font-medium text-md text-gray-500">
                      Dirección
                    </h3>
                    <textarea
                      name="address"
                      id="address"
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`w-full h-16 px-2 text-sm text-gray-700 placeholder-gray-600 border rounded-lg shadow-sm focus:border-transparent focus:outline-none focus:border-green-200 focus:ring-1 focus:ring-green-500 ${
                        formik.touched.address && formik.errors.address
                          ? "border-red-500"
                          : "border-gray-300"
                      }`}
                    ></textarea>
                    {formik.touched.address && formik.errors.address ? (
                      <small className="text-red-500">
                        {formik.errors.address}
                      </small>
                    ) : null}
                  </div>

                  <div className="grid-rows-1 flex flex-col items-start mb-4">
                    <h3 className="text-center font-semibold text-md text-gray-500">
                      Método de pago
                    </h3>
                    <select
                      id="paymentMethod"
                      name="paymentMethod"
                      value={formik.values.paymentMethod}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`w-full h-8 px-2 text-sm text-gray-700 placeholder-gray-600 border rounded-lg shadow-sm focus:border-transparent focus:outline-none focus:border-green-200 focus:ring-1 focus:ring-green-500 ${
                        formik.touched.paymentMethod &&
                        formik.errors.paymentMethod
                          ? "border-red-500"
                          : "border-gray-300"
                      }`}
                      type="paymentMethod"
                    >
                      <option value="">-- Seleccione --</option>
                      <option value="Efectivo">Efectivo</option>
                      <option value="Tarjeta">Tarjeta</option>
                    </select>
                    {formik.touched.paymentMethod &&
                    formik.errors.paymentMethod ? (
                      <small className="text-red-500">
                        {formik.errors.paymentMethod}
                      </small>
                    ) : null}
                  </div>

                  <div className="grid-rows-1 mt-10">
                    <button
                      type="submit"
                      className="h-12 w-full px-5 text-white transition-colors duration-150 bg-green-500 rounded-lg focus:shadow-outline hover:bg-green-400 flex justify-center items-center"
                    >
                      Ordenar
                    </button>
                  </div>
                </form>
              </div>
            </Card>
          </div>
        </div>

        <div
          className={`animate__animated  animate__fast ${
            menuState.preorderState === "review"
              ? "block animate__fadeIn"
              : "hidden animate__fadeOut "
          } w-full flex flex-col items-center  h-full`}
        >
          <div className="w-full  mt-10 mb-auto">
            <Card extraClass="mx-3 mb-4">
              <div className="flex justify-between items-center">
                <h1 className="text-2xl font-bold text-green-900 mb-3 text-center w-full">
                  Pedido realizado
                </h1>
              </div>
              <hr />
              <div className="w-full pt-3">
                <h2 className="text-center font-medium text-md text-gray-500">
                  Número de order
                </h2>
                <h2 className="text-center font-bold text-md text-purple-500">
                  {menuState.orden?.orderID}
                </h2>
                <hr className="mt-3" />
              </div>
              {/*  */}
              <div className="w-full pt-3">
                <h2 className="text-center font-medium text-md text-gray-500">
                  Resume de la orden
                </h2>
                <p className="text-left font-medium text-sm mt-2">
                  Fecha:{" "}
                  {new Date().toLocaleString("es-MX", {
                    year: "numeric",
                    month: "numeric",
                    day: "numeric",
                  })}
                </p>
                <p className="text-left font-medium text-sm mt-2">
                  Nombre:{menuState.orden?.clientName}{" "}
                </p>
                <p className="text-left font-medium text-sm mt-2">
                  Teléfono:{menuState.orden?.phone}
                </p>
                <p className="text-left font-medium text-sm mt-2">
                  Dirección: {menuState.orden?.address}
                </p>
                <p className="text-left font-medium text-sm mt-2">
                  Método de pago: {menuState.orden?.paymentMethod}
                </p>
                <h2 className="text-center font-medium text-md text-gray-500 mt-5">
                  Orden
                </h2>

                <div className="flex justify-between mt-5">
                  {menuState.orden?.order?.map((dish, index) => {
                    return (
                      <>
                        <div key={index} className="w-auto mb-3">
                          <h1 className="font-medium text-sm">
                            {dish?.name} ({dish.quantity})
                          </h1>
                        </div>
                        <h1 className="font-bold text-sm">${dish?.price}</h1>
                      </>
                    );
                  })}
                </div>
                <hr />
                <div className="flex justify-between mt-3">
                  <div className="w-auto mb-3">
                    <h1 className="font-medium text-sm">Total</h1>
                  </div>
                  <h1 className="font-bold text-sm text-red-500">
                    ${menuState.orden?.total}
                  </h1>
                </div>

                <div className="flex justify-center mt-3">
                  <div className="w-auto mb-3">
                    <button
                      onClick={() => {
                        reset();
                      }}
                      className="w-auto py-2 px-3 mb-5 rounded-lg text-md font-medium border shadow-sm focus:outline-none focus:ring transition text-yellow-600 bg-yellow-50 border-yellow-200 hover:bg-yellow-100 active:bg-yellow-200 focus:ring-white"
                    >
                      Realizar otra orden
                    </button>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>

        <div className="w-screen text-center mb-4">
          <p className="text-green-500 text-sm">
            Coded with ♥️ by{" "}
            <a
              href="https://carlospadron.dev"
              target="_blank"
              rel="noreferrer"
              className="text-purple-500"
            >
              Carlos Padrón
            </a>
          </p>
        </div>
      </div>

      {/* OVERLAY */}
      <div
        className={`bg-gray-100 opacity-80 top-0 right-0 left-0 bottom-0 flex justify-center items-center ${
          menuState.orderingDishes ? "fixed" : "hidden"
        }`}
      >
        <i className="bx bx-loader-alt bx-spin mr-1 text-7xl text-green-500"></i>
      </div>
    </>
  );
};

export default OrderDetails;
