import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tables: [],
  branches: [],
  isFetching: true,
  errorFetching: false,
  successFetching: false,

  isUpadting: false,
  errorUpdating: false,
  successUpdaing: false,

  isStoring: false,
  errorStoring: false,
  successStoring: false,

  message: "",
};

const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    resetState: (state, action) => {
      state.tables = [];
      state.branches = [];
      state.isFetching = true;
      state.errorFetching = false;
      state.successFetching = false;

      state.isUpadting = false;
      state.errorUpdating = false;
      state.successUpdaing = false;

      state.isStoring = false;
      state.errorStoring = false;
      state.successStoring = false;

      state.message = "";
    },
    tableInitialFetchSuccess: (state, action) => {
      state.tables = action.payload.tables;
      state.branches = action.payload.branches;
      state.isFetching = false;
      state.successFetching = true;
      state.errorFetching = false;
    },
    tableInitialFetchFailure: (state, action) => {
      state.isFetching = false;
      state.successFetching = false;
      state.errorFetching = true;
      state.message = action.payload.error;
    },

    storingTable: (state, action) => {
      state.isStoring = true;
      state.errorStoring = false;
      state.successStoring = false;
    },

    storingTableFailure: (state, action) => {
      state.isStoring = false;
      state.errorStoring = true;
      state.successStoring = false;
      state.message = action.payload.error;
    },

    storingTableSuccess: (state, action) => {
      state.isStoring = false;
      state.errorStoring = false;
      state.successStoring = true;
    },

    updatingTable: (state, action) => {
      state.isUpadting = true;
      state.errorUpdating = false;
      state.successUpdaing = false;
    },

    updatingTableFailure: (state, action) => {
      state.isUpadting = false;
      state.errorUpdating = true;
      state.successUpdaing = false;
      state.message = action.payload.error;
    },

    updatingTableSuccess: (state, action) => {
      state.isUpadting = false;
      state.errorUpdating = false;
      state.successUpdaing = true;
    },
  },
});

export const {
  resetState,
  tableInitialFetchSuccess,
  tableInitialFetchFailure,
  storingTable,
  storingTableSuccess,
  storingTableFailure,
  updatingTable,
  updatingTableSuccess,
  updatingTableFailure,
} = tableSlice.actions;
export default tableSlice.reducer;
