import React from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import { Constants } from "../utils/constants";

import { AdminTemplate } from "../pages/admin/AdminTemplate";

//Valiada que el usuario esté autenticado y sea de tipo admin
const AdminRoutes = ({ user, component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (user.isAuthenticated) {
          return (
            <AdminTemplate {...props}>
              <Component {...props} />
            </AdminTemplate>
          );
        } else {
          return <Redirect to="/login" />;
        }
      }}
    />
  );
};

AdminRoutes.propTypes = {
  user: PropTypes.object.isRequired,
  component: PropTypes.object.isRequired,
};

export default AdminRoutes;
