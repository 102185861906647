import { environments } from "../utils/constants";
import generalService from "./generalService";
const menuService = {
  //Admin
  getMenus: () => {
    let token = generalService.getToken();

    return fetch(`${environments.BASE_URL}/get-menus`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },

  getMenuForSelect: () => {
    let token = generalService.getToken();

    return fetch(`${environments.BASE_URL}/get-menus-select`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },

  storeMenu: (body) => {
    let token = generalService.getToken();

    console.log(`${environments.BASE_URL}/store-menu`);
    return fetch(`${environments.BASE_URL}/store-menu`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body,
    });
  },

  getMenuDetail: (id) => {
    let token = generalService.getToken();

    return fetch(`${environments.BASE_URL}/get-menu-detail?id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },

  udpateMenu: (body) => {
    let token = generalService.getToken();

    return fetch(`${environments.BASE_URL}/update-menu/${body.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    });
  },

  deleteMenu: (id) => {
    let token = generalService.getToken();

    return fetch(`${environments.BASE_URL}/delete-menu/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },

  //Client
  getRestaurantAndMenus: (idRestaurant) => {
    return fetch(
      `${environments.BASE_URL}/get-restaurant-info-menus/${idRestaurant}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },

  getMenuSections: (idMenu, idRestaurant) => {
    return fetch(
      `${environments.BASE_URL}/get-restaurant-menu-sections?menu=${idMenu}&idRestaurant=${idRestaurant}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },

  getSectionsDishes: (idSection, idRestaurant) => {
    return fetch(
      `${environments.BASE_URL}/get-restaurant-menu-sections-dishes?section=${idSection}&idRestaurant=${idRestaurant}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  },
};

export default menuService;
