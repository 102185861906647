import { environments } from "../utils/constants";
import generalService from "./generalService";

const ordersService = {
  getOrders: (initialDate, finalDate, status, offset) => {
    let token = generalService.getToken();

    return fetch(
      `${environments.BASE_URL}/get-orders?initialDate=${initialDate}&finalDate=${finalDate}&status=${status}&offset=${offset}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  },

  getOrder: (id) => {
    let token = generalService.getToken();

    return fetch(`${environments.BASE_URL}/get-order?id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },

  placeOrder: (body) => {
    return fetch(`${environments.BASE_URL}/place-order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  },

  updateOrderStatus: (orderID) => {
    let token = generalService.getToken();

    return fetch(`${environments.BASE_URL}/update-order-status/${orderID}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  },
};

export default ordersService;
