import React from "react";

import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

export const Sidebar = () => {
  //assigning location variable
  const location = useLocation();
  //destructuring pathname from location
  const { pathname } = location;

  const menuArray = [
    {
      name: "Dashboard",
      icon: "bx bx-server",
      route: "/dashboard",
    },
    {
      name: "Menús",
      icon: "bx bx-restaurant",
      route: "/menus",
    },
    {
      name: "Órdenes",
      icon: "bx bx-dish",
      route: "/ordenes",
    },
    {
      name: "Restaurante",
      icon: "bx bx-store",
      route: "/restaurante",
    },
  ];

  return (
    <>
      <div className={`sidebar close`}>
        <div className="logo-details">
          <span style={{ display: "flex", marginLeft: "19px" }}>
            <img
              style={{ height: "40px", width: "40px", maxWidth: "40px" }}
              className="rounded-lg"
              src="/SnapMenuMediumLogo.png"
              alt=""
            />
          </span>

          <span
            className="logo_name"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              style={{ width: "150px", height: "40px", marginLeft: "16px" }}
              src="/SnapMenuMediumLogo.png"
              className="rounded-lg"
              alt=""
            />
          </span>
        </div>
        <ul className="nav-links">
          {menuArray.map((menu, index) => {
            return (
              <li key={`${index}`}>
                <NavLink to={`${menu.route}`}>
                  <i
                    className={`${menu.icon} ${
                      pathname.includes(menu.route) ? "active" : ""
                    }`}
                  ></i>
                  <span className="link_name">{menu.name}</span>
                </NavLink>
                <ul className="sub-menu blank">
                  <li>
                    <NavLink className="link_name" to={`${menu.route}`}>
                      {menu.name}
                    </NavLink>
                  </li>
                </ul>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};
