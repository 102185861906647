//Core
import React from "react";

//Redux
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../redux/features/authSlice";
import { updatePendingOrdersCounter } from "../redux/features/orderSlice";

//Components
import { Link } from "react-router-dom";
import { useEffect } from "react";

//
import socket from "../utils/socket";

export const Header = ({ history }) => {
  const authState = useSelector((state) => state.auth);
  const ordersState = useSelector((state) => state.orderReducer);

  const dispatch = useDispatch();

  const logout = () => {
    localStorage.removeItem("session");
    localStorage.removeItem("SIO_ID");
    dispatch(updatePendingOrdersCounter({ count: 0 }));
    dispatch(logOut());
  };

  useEffect(() => {
    if (!authState.isAuthenticated) {
      history.replace("/login");
    }
  }, [authState.isAuthenticated]);

  useEffect(() => {
    socket.on("order:newOrder", (_) => {

      let count =
        localStorage.getItem("orderCounter") === undefined
          ? 0
          : parseInt(localStorage.getItem("orderCounter"));
      dispatch(updatePendingOrdersCounter({ count: count + 1 }));
    });
  }, []);

  useEffect(() => {
    localStorage.setItem("orderCounter", ordersState.pendingOrders);
  }, [ordersState.pendingOrders]);

  return (
    <>
      <div className="header fixed top-0 right-0">
        <div className="header-content">
          <div className="relative inline-block mr-3 mt-2">
            <Link
              to="/ordenes"
              className="bx bxs-bell text-yellow-300 text-3xl cursor-pointer"
            ></Link>

            {ordersState.pendingOrders > 0 ? (
              <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-purple-600 rounded-full">
                {ordersState.pendingOrders}
              </span>
            ) : (
              <></>
            )}
          </div>

          <i
            className="bx bx-log-out text-blue-500 text-3xl cursor-pointer mr-3"
            onClick={() => {
              ////socket.disconnect()
              socket.disconnect();
              logout();
            }}
          ></i>
        </div>
      </div>
    </>
  );
};
