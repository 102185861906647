import React from "react";
import { Card } from "../../components/Card";

const Page404 = () => {
  return (
    <div>
      <div className="bg-green-200 w-screen h-screen">
        
        <div className=" animate__animated  animate__fadeIn animate__fast w-full flex justify-center h-full">
          <div className="w-full sm:w-8/12 md:w-6/12 lg:w-6/12 mt-auto mb-auto">
            <Card>
            <div className="flex flex-col items-center">
                <i
                  className="bx bx-error text-yellow-300"
                  style={{ fontSize: "70px" }}
                ></i>
                <h2 className="text-2xl font-bold text-green-900 mb-3 text-center">
                  Página no encontrada
                </h2>
                
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page404;
