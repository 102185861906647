import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  //General menu info
  restaurant:
    localStorage.getItem("clientInfo") !== undefined
      ? JSON.parse(localStorage.getItem("clientInfo"))
      : {},

  //initial Menus Fecth
  isFetching: true,
  isSuccess: false,
  isError: false,

  //Menus
  menus: [],
  //Sections
  sections: [],
  fetchingSections: false,
  errorFetchingSections: false,
  successFetchingSections: false,

  //Dishes

  fetchingDishes: false,
  errorFetchingDishes: false,
  successFetchingDishes: false,

  preorder:
    localStorage.getItem("CART") === undefined ||
    localStorage.getItem("CART") === null
      ? []
      : JSON.parse(localStorage.getItem("CART")),
  orden: {},
  //Message
  message: "",

  preorderState: "details",

  orderingDishes: false,
  orderingDishesFailure: false,
};

const clientMenuSlice = createSlice({
  name: "clientMenu",
  initialState,
  reducers: {
    resetMenuSection: (state, action) => {
      //Sections
      state.sections = [];
      state.fetchingSections = false;
      state.errorFetchingSections = false;
      state.successFetchingSections = false;

      //Dishes
      state.fetchingDishes = false;
      state.errorFetchingDishes = false;
      state.successFetchingDishes = false;

      //Messge
      state.message = "";
    },
    menusInitialFetchSucced: (state, action) => {
      state.isSuccess = true;
      state.isError = true;
      state.isFetching = false;
      state.menus = action.payload.menus;
      state.restaurant = action.payload.restaurant;
    },
    menusInitialFetchFailure: (state, action) => {
      state.isSuccess = false;
      state.isError = true;
      state.isFetching = false;
      state.message = action.payload.error;
    },

    fetchMenuSections: (state, action) => {
      state.fetchingSections = true;
      state.errorFetchingSections = false;
      state.successFetchingSections = false;
    },
    errorFetchMenuSections: (state, action) => {
      state.fetchingSections = false;
      state.errorFetchingSections = true;
      state.successFetchingSections = false;
      state.message = action.payload.error;
    },
    successFetchMenuSections: (state, action) => {
      state.fetchingSections = false;
      state.errorFetchingSections = false;
      state.successFetchingSections = true;
      state.sections = action.payload.sections;
    },

    fetchMenuDishes: (state, action) => {
      state.fetchingDishes = true;
      state.errorFetchingDishes = false;
      state.successFetchingDishes = false;
    },
    errorFetchMenuDishes: (state, action) => {
      state.fetchingDishes = false;
      state.errorFetchingDishes = true;
      state.successFetchingDishes = false;
      state.message = action.payload.error;
    },
    successFetchMenuDishes: (state, action) => {
      state.fetchingDishes = false;
      state.errorFetchingDishes = false;
      state.successFetchingDishes = true;

      state.sections = action.payload.sections;
    },
    updatePreOrder: (state, action) => {
      switch (action.payload.action) {
        case "add":
          state.preorder.push(action.payload.dish);

          break;
        case "remove":
          state.preorder = state.preorder.filter(
            (dish) => dish.id !== action.payload.id
          );
          break;

        case "increase":
          let currentPreOrderIncrease = state.preorder;
          let indexIncrease = currentPreOrderIncrease.findIndex(
            (dish) => dish.id === action.payload.id
          );

          currentPreOrderIncrease[indexIncrease].details.quantity =
            currentPreOrderIncrease[indexIncrease].details.quantity + 1;

          state.preorder = currentPreOrderIncrease;
          break;

        case "decrease":
          let currentPreOrderDecrease = state.preorder;
          let indexDecrease = currentPreOrderDecrease.findIndex(
            (dish) => dish.id === action.payload.id
          );

          currentPreOrderDecrease[indexDecrease].details.quantity =
            currentPreOrderDecrease[indexDecrease].details.quantity - 1;

          state.preorder = currentPreOrderDecrease;
          break;

        default:
          break;
      }
    },

    updatePreorderState: (state, action) => {
      state.preorderState = action.payload;
    },

    orderingDishes: (state, action) => {
      state.orderingDishes = true;

      state.orderingDishesFailure = false;
    },
    orderingDishesSuccess: (state, action) => {
      state.orderingDishes = false;
      state.preorderState = "review";
      state.orden = action.payload;
      state.orderingDishesFailure = false;
      state.preorder = [];
    },
    orderingDishesFailure: (state, action) => {
      state.orderingDishes = false;

      state.orderingDishesFailure = true;
      state.message = action.payload;
    },

    resetCart: (state, action) => {
      state.preorderState = "details";
    },
  },
});

export const {
  menusInitialFetchSucced,
  menusInitialFetchFailure,
  fetchMenuSections,
  errorFetchMenuSections,
  successFetchMenuSections,
  fetchMenuDishes,
  errorFetchMenuDishes,
  successFetchMenuDishes,
  resetMenuSection,
  updatePreOrder,
  updatePreorderState,
  orderingDishes,
  orderingDishesSuccess,
  orderingDishesFailure,
  resetCart,
} = clientMenuSlice.actions;
export default clientMenuSlice.reducer;
