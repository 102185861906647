import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  restaurantID: "",
  name: "",
  manager: "",
  managerPhone: "",
  managerEmail: "",
  logoURL: "",
  qrURL: "",
  isFetching: true,
  isSuccess: false,
  isError: false,
  isUpdating: false,
  errorUpdating: false,
  isUpdatingQR: false,
  errorUpdatingQR: false,
  errorMessage: "",
  doneUpdating: false,
  doneUpdatingQR: false,
};

const restaurantSlice = createSlice({
  name: "restaurant",
  initialState,
  reducers: {
    resetState: (state, action) => {
      state.restaurantID = "";
      state.name = "";
      state.manager = "";
      state.managerPhone = "";
      state.managerEmail = "";
      state.logoURL = "";
      state.qrURL = "";
      state.licenceExpirationDate = "";
      state.planID = "";
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      state.isUpdating = false;
      state.errorUpdating = false;
      state.errorMessage = "";
      state.doneUpdating = false;
      state.isUpdatingQR = false;
      state.errorUpdatingQR = false;
      state.doneUpdatingQR = false;
    },

    restaurantInitialFecthSucceed: (state, action) => {
      state.restaurantID = action.payload.restaurantID;
      state.name = action.payload.name;
      state.manager = action.payload.manager;
      state.managerPhone = action.payload.managerPhone;
      state.managerEmail = action.payload.managerEmail;
      state.logoURL = action.payload.logoURL;
      state.qrURL = action.payload.qrURL;

      state.licenceExpirationDate = action.payload.licenceExpirationDate;
      state.planID = action.payload.planID;
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
      state.isUpdating = false;
      state.errorUpdating = false;
      state.doneUpdating = false;
      state.errorMessage = "";
    },

    restaurantInitialFetchFailure: (state, action) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = action.payload.error;
    },

    restaurantUpdating: (state, action) => {
      state.isUpdating = true;
      state.errorUpdating = false;
      state.errorMessage = "";
      state.doneUpdating = false;
      state.logoURL = action.payload.logoURL;
      state.managerPhone = action.payload.managerPhone;
      state.managerEmail = action.payload.managerEmail;
    },
    restaurantUpdateFailed: (state, action) => {
      state.isUpdating = false;
      state.errorUpdating = true;
      state.errorMessage = action.payload.error;
      state.doneUpdating = false;
    },
    restaurantUpdateSucceed: (state, action) => {
      state.isUpdating = false;
      state.errorUpdating = false;
      state.errorMessage = "";
      state.doneUpdating = true;
    },

    restaurantQRUpdating: (state, action) => {
      state.isUpdatingQR = true;
      state.errorUpdatingQR = false;
      state.errorMessage = "";
      state.doneUpdatingQR = false;
    },
    restaurantQRUpdateFailed: (state, action) => {
      state.isUpdatingQR = false;
      state.errorUpdatingQR = true;
      state.errorMessage = action.payload.error;
      state.doneUpdatingQR = false;
    },
    restaurantQRUpdateSucceed: (state, action) => {
      state.isUpdatingQR = false;
      state.errorUpdatingQR = false;
      state.errorMessage = "";
      state.qrURL = action.payload.qrURL;
      state.doneUpdatingQR = true;
    },
  },
});

export const {
  resetState,
  restaurantInitialFecthSucceed,
  restaurantInitialFetchFailure,
  restaurantUpdating,
  restaurantUpdateFailed,
  restaurantUpdateSucceed,
  restaurantQRUpdating,
  restaurantQRUpdateFailed,
  restaurantQRUpdateSucceed,
} = restaurantSlice.actions;

export default restaurantSlice.reducer;
