import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rows: [],
  totalRecords: 0,

  fetchingOrders: false,
  fetchingOrdersSuccess: false,
  fetchingOrdersFailure: false,

  fetchingOrderDetails: false,
  fetchingOrderDetailsSuccess: false,
  fetchingOrderDetailsFailue: false,

  selectedOrder: {},
  updatingSelectedOrderStatus: false,
  pendingOrders:
  localStorage.getItem("orderCounter") === undefined
  ? 0
  : parseInt(localStorage.getItem("orderCounter")),
  message: "",
};

const orderSlice = createSlice({
  name: "orderSlice",
  initialState,
  reducers: {
    reset: (state, action) => {
      state.rows = [];
      state.totalRecords = 0;
      state.fetchingOrders = false;
      state.fetchingOrdersSuccess = false;
      state.fetchingOrdersFailure = false;
      state.fetchingOrderDetails = false;
      state.fetchingOrderDetailsSuccess = false;
      state.fetchingOrderDetailsFailue = false;
      state.updatingSelectedOrderStatus = false;
      state.message = "";
      state.selectedOrder = {};
    },
    updatePendingOrdersCounter: (state, action) => {
      state.pendingOrders = action.payload.count;
    },
    fetchingOrders: (state, action) => {
      state.fetchingOrders = true;
      state.fetchingOrdersSuccess = false;
      state.fetchingOrdersFailure = false;
    },
    fetchingOrdersSuccess: (state, action) => {
      state.fetchingOrders = false;
      state.fetchingOrdersSuccess = true;
      state.fetchingOrdersFailure = false;
      state.rows = action.payload.rows;
      state.totalRecords = action.payload.skip
        ? state.totalRecords
        : action.payload.total;
    },
    fetchingOrdersFailure: (state, action) => {
      state.fetchingOrders = false;
      state.fetchingOrdersSuccess = false;
      state.fetchingOrdersFailure = true;
      state.message = action.payload.message;
    },

    fetchingOrderDetails: (state, action) => {
      state.fetchingOrderDetails = true;
      state.fetchingOrderDetailsSuccess = false;
      state.fetchingOrderDetailsFailue = false;
    },

    fetchingOrderDetailsSuccess: (state, action) => {
      state.fetchingOrderDetails = false;
      state.fetchingOrderDetailsSuccess = true;
      state.fetchingOrderDetailsFailue = false;
      state.selectedOrder = action.payload.order;
    },

    fetchingOrderDetailsFailue: (state, action) => {
      state.fetchingOrderDetails = false;
      state.fetchingOrderDetailsSuccess = false;
      state.fetchingOrderDetailsFailue = true;
      state.message = action.payload.message;
    },

    updateOrderStatus: (state, action) => {
      state.selectedOrder.status =
        state.selectedOrder.status === "Abierta" ? "Terminada" : "Abierta";
      state.updatingSelectedOrderStatus = true;
    },
    updateOrderStatusDone: (state, action) => {
      state.updatingSelectedOrderStatus = false;
    },
  },
});

export const {
  reset,
  fetchingOrders,
  fetchingOrdersSuccess,
  fetchingOrdersFailure,
  fetchingOrderDetails,
  fetchingOrderDetailsSuccess,
  fetchingOrderDetailsFailue,
  updateOrderStatus,
  updateOrderStatusDone,
  updatePendingOrdersCounter,
} = orderSlice.actions;

export default orderSlice.reducer;
