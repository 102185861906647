import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  //Items
  menus: [],
  //initial fetch
  isFetching: true,
  isSuccess: false,
  isError: false,

  //Update
  isUpdating: false,
  doneUpdating: false,
  errorUpdating: false,

  //store
  storing: false,
  doneStoring: false,
  errorStoring: false,

  //get
  fetchingMenu: false,
  fetchingMenuSucceed: false,
  fetchingMenuFailure: false,
  //error
  errorMessage: "",
  //
  deletingMenu: false,
  successDeletingMenu: false,
  errorDeletingMenu: false,
};

const adminMenuSlice = createSlice({
  name: "adminMenu",
  initialState,
  reducers: {
    resetState: (state, action) => {
      state.menus = [];
      state.isFetching = true;
      state.isSuccess = false;
      state.isError = false;
      state.isUpdating = false;
      state.doneUpdating = false;
      state.errorUpdating = false;
      state.storing = false;
      state.doneStoring = false;
      state.errorStoring = false;

      state.errorMessage = "";
      state.fetchingMenu = false;
      state.fetchingMenuSucceed = false;
      state.fetchingMenuFailure = false;

      state.deletingMenu = false;
      state.successDeletingMenu = false;
      state.errorDeletingMenu = false;
    },
    menusInitialFetchSucceed: (state, action) => {
      state.menus = action.payload;
      state.isFetching = false;
      state.isSuccess = true;
    },
    menusInitialFetchFailure: (state, action) => {
      state.menus = [];
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = true;
      state.errorMessage = action.payload.error;
    },

    /*  */

    deletingMenu: (state, action) => {
      state.deletingMenu = true;
      state.errorDeletingMenu = false;
      state.successDeletingMenu = false;
      state.errorMessage = "";
    },
    errorDeletingMenu: (state, action) => {
      console.log(action);
      state.deletingMenu = false;
      state.errorDeletingMenu = true;
      state.successDeletingMenu = false;
      state.errorMessage = action.payload.error;
    },
    successDeletingMenu: (state, action) => {
      state.deletingMenu = false;
      state.errorDeletingMenu = false;
      state.successDeletingMenu = true;
      state.errorMessage = "";
    },

    /*  */
    storingMenu: (state, action) => {
      state.storing = true;
      state.doneStoring = false;
      state.errorStoring = false;
    },
    storingMenuSucceed: (state, action) => {
      state.storing = false;
      state.doneStoring = true;
      state.errorStoring = false;
      //state.isSuccess = false;
    },
    storingMenuFailure: (state, action) => {
      console.log(action);
      state.storing = false;
      state.doneStoring = false;
      state.errorStoring = true;
      state.errorMessage = action.payload.error;
    },
    /*  */
    updatingMenuInfo: (state, action) => {
      state.isUpdating = true;
      state.doneUpdating = false;
      state.errorUpdating = false;
      state.errorMessage = "";
    },
    updatingMenuInfoSucceed: (state, action) => {
      state.isUpdating = false;
      state.doneUpdating = true;
      state.errorUpdating = false;
      state.errorMessage = "";
    },
    updatingMenuInfoFailure: (state, action) => {
      state.isUpdating = false;
      state.doneUpdating = false;
      state.errorUpdating = true;
      state.errorMessage = action.payload;
    },
    fetchingMenu: (state, action) => {
      state.fetchingMenu = true;
      state.fetchingMenuSucceed = false;
      state.fetchingMenuFailure = false;
      state.errorMessage = "";
    },
    fetchingMenuSucceed: (state, action) => {
      state.fetchingMenu = false;
      state.fetchingMenuSucceed = true;
      state.fetchingMenuFailure = false;
      state.errorMessage = "";
    },
    fetchingMenuFailure: (state, action) => {
      state.fetchingMenu = false;
      state.fetchingMenuSucceed = false;
      state.fetchingMenuFailure = true;
      state.errorMessage = action.payload;
    },
    /*  */
    clearErrors: (state, action) => {
      state.errorMessage = "";
      state.errorUpdating = false;
      state.errorStoring = false;
    },
  },
});

export const {
  resetState,
  menusInitialFetchSucceed,
  menusInitialFetchFailure,

  deletingMenu,
  errorDeletingMenu,
  successDeletingMenu,

  updatingMenuInfo,
  updatingMenuInfoSucceed,
  updatingMenuInfoFailure,
  storingMenu,
  storingMenuSucceed,
  storingMenuFailure,

  fetchingMenu,
  fetchingMenuSucceed,
  fetchingMenuFailure,
  clearErrors,
} = adminMenuSlice.actions;

export default adminMenuSlice.reducer;
