import React from "react";
import { useSelector } from "react-redux";
import { Constants } from "../../utils/constants";
import OrderDetails from "./OrderDetails/OrderDetails";

const LocalOrder = () => {
  const menuState = useSelector(
    (state) => state.clientMenu,
    (prev, next) => true
  );

  return (
    <div>
      <div className="bg-white top-0 right-0 left-0 bottom-0 overflow-y-scroll absolute">
        <div className="relative h-80 bg-green-200">
          <div className="content">
            <div className="w-full  pt-10 mb-auto mx-3">
              <div className="flex flex-col items-center">
                <img
                  src={
                    menuState.restaurant.logoURL === "" ||
                    menuState.restaurant.logoURL === null ||
                    menuState.restaurant.logoURL === undefined
                      ? Constants.defaultImg
                      : menuState.restaurant.logoURL
                  }
                  className="rounded-md"
                  alt=""
                  style={{ maxWidth: "200px" }}
                />
              </div>
              <div className="flex flex-col items-center pt-3">
                <h1 className="text-2xl font-semibold text-center">
                  {menuState.restaurant.name}
                </h1>
              </div>
            </div>

            <OrderDetails />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocalOrder;
