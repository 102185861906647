import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { DomainRoutes } from "./DomainRoutes";
import { environments } from "../utils/constants";
import { SubdomainRoutes } from "./SubdomainRoutes";

export const MainRouter = () => {
  const RenderApp = () => {
    const subdomain = window.location.host.split(".");

    if (!subdomain[0].includes("qrmenu")) {
      //Admin
      return <DomainRoutes />;
    } else if (subdomain[0].includes("qrmenu")) {
      //Interactive menu
      return <SubdomainRoutes />;
    }
  };

  return (
    <Router>
      <Switch>
        <RenderApp />
      </Switch>
    </Router>
  );
};
