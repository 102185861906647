import { configureStore } from "@reduxjs/toolkit";
//Admin
import authReducer from "../features/authSlice";
import dashboardReducer from "../features/dashboardSlice";
import branchesReducer from "../features/branchesSlice";
import generalMenuReducer from "../features/generalMenuStore";
import restaurantReducer from "../features/restaurantSlice";
import adminMenuReducer from "../features/adminMenuSlice";
import tableReducer from "../features/tableSlice";
import orderReducer from "../features/orderSlice";

import clientMenuReducer from "../features/clientMenuSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    dashboard: dashboardReducer,
    restaurant: restaurantReducer,
    branch: branchesReducer,
    generalMenu: generalMenuReducer,
    adminMenu: adminMenuReducer,
    table: tableReducer,
    clientMenu: clientMenuReducer,
    orderReducer: orderReducer,
  },
});

export const setupStore = (preloadedState) => {
  return configureStore({
    reducer: {
      // auth: authReducer,
      // dashboard: dashboardReducer,
      // restaurant: restaurantReducer,
      // branch: branchesReducer,
      // generalMenu: generalMenuReducer,
      // adminMenu: adminMenuReducer,
      // table: tableReducer,
      // clientMenu: clientMenuReducer,
      // orderReducer: orderReducer,
      preloadedState
    },
  })
}