import io from "socket.io-client";
import { environments } from "../utils/constants";

const socket = io(`${environments.BASE_URL}`, {
  autoConnect: false,
  path: "/snapmenu/api/socket.io/",
  transports: ["websocket"],
});

socket.on("session", ({ sessionID, restaurantID }) => {
  socket.auth = { sessionID, restaurantID };
  localStorage.setItem("SIO_ID", JSON.stringify({ sessionID, restaurantID }));
});

export default socket;
