import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  menus: [],
  isFetching: true,
  isSuccess: false,
  isError: false,
};

const generalMenuSlice = createSlice({
  name: "generalMenu",
  initialState,
  reducers: {
    resetState: (state, action) => {
      state.menus = [];
      state.isFetching = true;
      state.isSuccess = false;
      state.isError = false;
    },
    menusInitialFetchSucced: (state, action) => {
      state.menus = action.payload;
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
    },
    menusInitialFetchFailed: (state, action) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = true;
    },
  },
});

export const { resetState, menusInitialFetchSucced, menusInitialFetchFailed } =
  generalMenuSlice.actions;

export default generalMenuSlice.reducer;
