import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

//Error
import Page404 from "../pages/errors/Page404";
import LazyLoad from "../pages/lazyload/LazyLoad";

import MenuClient from "../pages/client/MenuClient/MenuClient";
import ClientRoutes from "./ClientRoutes";
import LocalOrder from "../pages/client/LocalOrder";

const SelectDishesMenu = React.lazy(() =>
  import("../pages/client/SelectDishesMenu/SelectDishesMenu")
);

export const SubdomainRoutes = () => {
  return (
    <React.Suspense fallback={<LazyLoad />}>
      <Router>
        <Switch>
          <Route exact path="/:idRestaurant/" component={MenuClient} />
          <ClientRoutes
            exact
            path="/menu/:idRestaurant/:idMenu"
            component={SelectDishesMenu}
          />
          <Route exact path="/:idRestaurant/preporden" component={LocalOrder} />
          <Route component={Page404} />;
        </Switch>
      </Router>
    </React.Suspense>
  );
};
