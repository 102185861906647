import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  //Items
  branches: [],
  menus: [],
  //initial fetch
  isFetching: true,
  isSuccess: false,
  isError: false,
  //Update
  isUpdating: false,
  doneUpdating: false,
  errorUpdating: false,
  //store
  storing: false,
  doneStoring: false,
  errorStoring: false,
  //disable
  disabling: false,
  doneDisabling: false,
  errorDisabling: false,

  //get
  fetchingBranch: false,
  fetchingBranchSucceed: false,
  fetchingBranchFailure: false,
  //error
  errorMessage: "",
  //
};

const branchSlice = createSlice({
  name: "branch",
  initialState,
  reducers: {
    resetState: (state, action) => {
      state.branches = [];
      state.menus = [];
      state.isFetching = true;
      state.isSuccess = false;
      state.isError = false;
      state.isUpdating = false;
      state.doneUpdating = false;
      state.errorUpdating = false;
      state.storing = false;
      state.doneStoring = false;
      state.errorStoring = false;

      state.disabling = false;
      state.doneDisabling = false;
      state.errorDisabling = false;

      state.errorMessage = "";
      state.branchInfo = {};
      state.fetchingBranch = false;
      state.fetchingBranchSucceed = false;
      state.fetchingBranchFailure = false;
    },
    branchesInitialFetchSucceed: (state, action) => {
      state.branches = action.payload.branches;
      state.menus = action.payload.menus;
      state.isFetching = false;
      state.isSuccess = true;
    },
    branchesInitialFetchFailure: (state, action) => {
      state.branches = [];
      state.menus = [];
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = true;
      state.errorMessage = action.payload.error;
    },

    /*  */
    storingBranch: (state, action) => {
      state.storing = true;
      state.doneStoring = false;
      state.errorStoring = false;
    },
    storingBranchSucceed: (state, action) => {
      state.storing = false;
      state.doneStoring = true;
      state.errorStoring = false;
      //state.isSuccess = false;
    },
    storingBranchFailure: (state, action) => {
      state.storing = false;
      state.doneStoring = false;
      state.errorStoring = true;
      state.errorMessage = action.payload.error;
    },
    /*  */
    updatingBranchInfo: (state, action) => {
      state.isUpdating = true;
      state.doneUpdating = false;
      state.errorUpdating = false;
      state.errorMessage = "";
    },
    updatingBranchInfoSucceed: (state, action) => {
      state.isUpdating = false;
      state.doneUpdating = true;
      state.errorUpdating = false;
      state.errorMessage = "";
    },
    updatingBranchInfoFailure: (state, action) => {
      state.isUpdating = false;
      state.doneUpdating = false;
      state.errorUpdating = true;
      state.errorMessage = action.payload;
    },
    disablingBranch: (state, action) => {
      state.disabling = true;
      state.doneDisabling = false;
      state.errorDisabling = false;
      state.errorMessage = "";
    },
    disablingBranchSucceed: (state, action) => {
      state.disabling = false;
      state.doneDisabling = true;
      state.errorDisabling = false;

      state.errorMessage = "";
    },
    disablingBranchFailure: (state, action) => {
      state.disabling = false;
      state.doneDisabling = true;
      state.errorDisabling = true;

      state.errorMessage = action.payload;
    },
    /*  */
    clearErrors: (state, action) => {
      state.errorMessage = "";
      state.errorUpdating = false;
      state.errorStoring = false;
      state.errorDisabling = false;
    },
  },
});

export const {
  resetState,
  branchesInitialFetchSucceed,
  branchesInitialFetchFailure,

  updatingBranchInfo,
  updatingBranchInfoSucceed,
  updatingBranchInfoFailure,
  storingBranch,
  storingBranchSucceed,
  storingBranchFailure,

  disablingBranch,
  disablingBranchSucceed,
  disablingBranchFailure,
  clearErrors,
} = branchSlice.actions;

export default branchSlice.reducer;
