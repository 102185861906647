import React from "react";
import { Card } from "../../../components/Card";

//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  menusInitialFetchSucced,
  menusInitialFetchFailure,
} from "../../../redux/features/clientMenuSlice";

//Router
import { Link, useParams } from "react-router-dom";

//Services
import menuService from "../../../services/menuService";

//Components
import ContentShimmer from "react-content-shimmer";
import { useEffect } from "react";
import { Constants } from "../../../utils/constants";

const MenuClient = () => {
  const dispatch = useDispatch();
  const { idRestaurant } = useParams();

  const menuState = useSelector((state) => state.clientMenu);

  //Content
  const PageContent = () => {
    if (menuState.isFetching) {
      return (
        <ContentShimmer
          size={{ height: "300" }}
          style={{ width: "100%" }}
          rounded="3%"
          elevation="0"
          background="#f7f7f7"
          foreground="#eee"
        />
      );
    } else if (menuState.isSuccess) {
      return (
        <>
          <div className="flex flex-col items-center">
            <img
              src={menuState.restaurant.logoURL}
              className="rounded-lg"
              alt=""
              style={{ maxWidth: "200px" }}
            />
          </div>
          <div className="flex flex-col items-center pt-3">
            <h1 className="text-2xl font-semibold text-center">
              {menuState.restaurant.name}
            </h1>
          </div>
          <div className="flex flex-col items-center">
            <h1 className="text-2xl font-medium">Elige un menú</h1>
          </div>
          <div className="w-full pt-3">
            <div className="flex flex-wrap">
              {/*  */}
              {menuState.menus.map((menu) => {
                return (
                  <div
                    key={menu._id}
                    className="w-full md:w-4/12 lg:w-6/12 px-3 pb-6"
                  >
                    <div
                      className={`h-auto w-auto rounded-lg  bg-white shadow-md`}
                    >
                      <Link
                        to={`/menu/${idRestaurant}/${menu._id}?menu=${encodeURI(
                          menu.name
                        )}`}
                      >
                        <div className="relative cursor-pointer">
                          <div className="absolute inset-0 z-10 rounded-t-lg bg-transparent text-center flex flex-col items-center justify-center :opacity-100 duration-300">
                            <h5 className="text-xl font-semibold text-yellow-300">
                              {menu.name}
                            </h5>
                          </div>
                          <div className="relative">
                            <div className="h-48 flex flex-wrap content-center">
                              <img
                                src={
                                  menu.imageURL === "" ||
                                  menu.imageURL === null ||
                                  menu.imageURL === undefined
                                    ? Constants.defaultImg
                                    : menu.imageURL
                                }
                                className="rounded-t-lg object-cover h-48 w-full"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              })}
              {/*  */}
            </div>
          </div>
        </>
      );
    } else if (menuState.isError) {
      return (
        <div className="flex flex-col items-center">
          <i
            className="bx bx-confused text-yellow-300"
            style={{ fontSize: "70px" }}
          ></i>
          <h2 className="text-2xl font-bold text-green-900 mb-3 text-center">
            Ocurrió un error
          </h2>
          <p className="text-lg font-bold text-green-700 mb-5 text-center">
            {menuState.message}
          </p>
        </div>
      );
    } else {
      return <></>;
    }
  };

  //Actions
  const fetchInitilInfo = async (idRestaurant) => {
    try {
      let result = await menuService.getRestaurantAndMenus(idRestaurant);

      let json = await result.json();

      if (json.error) {
        return dispatch(menusInitialFetchFailure({ error: json.message }));
      }

      let { _id, name, logoURL, menus } = json.response;

      localStorage.setItem(
        "clientInfo",
        JSON.stringify({
          _id,
          name,
          logoURL,
          idRestaurant,
        })
      );
      return dispatch(
        menusInitialFetchSucced({
          menus,
          restaurant: {
            _id,
            name,
            logoURL,
            idRestaurant,
          },
        })
      );
    } catch (error) {
      return dispatch(menusInitialFetchFailure({ error: error.message }));
    }
  };

  useEffect(() => {
    fetchInitilInfo(idRestaurant);
    return () => {};
  }, []);

  return (
    <>
      <div className="w-screen h-screen bg-restaurant-layer">
        <div className="animate__animated  animate__fadeIn animate__fast w-full flex justify-center h-full">
          <div className="w-full sm:w-10/12  lg:w-10/12 mt-auto mb-auto">
            <div className="mx-10">
              <Card>
                <PageContent />
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MenuClient;
